import "./PlanAndPricing.scss";
import { useEffect, useState } from "react";
//import "./PlanAndPricing.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { publicRequest } from "../../requestMethod";

export const PlanAndPricing = () => {
    const navigate = useNavigate();
    const [typeOfPlan, setTypeOfPlan] = useState("month");

    const [selectedPlan, setSelectedPlan] = useState(3);
    const [plans, setPlans] = useState([]);

    const monthSelector = (type) => {
        setTypeOfPlan(type);
    };

    const yearSelector = (type) => {
        setTypeOfPlan(type);
    };

    const planSelectorHandler = (id) => {
        setSelectedPlan(id);
        alert(id);
        localStorage.setItem("plan", id);
        navigate("/signUp");
    };


    useEffect(() => {
        const fetchPlan = async () => {
            try {
                const res = await publicRequest.get(`/plan/`);
                setPlans(res?.data?.plans);
                setSelectedPlan(res?.data?.plans[2]._id);
            } catch (error) {
                // console.log(error);
            }
        };
        fetchPlan();
    }, []);

    return (
        <div className="PlanAndPricingContainer" id="pricing">
            <div className="PlanAndPricingWrapper">
                <div className="PlanAndPricingcont">
                    <div className="PlanAndPricingheading">Plans & Pricing</div>
                    <h3 className="PlanAndPricingdesc">
                        Whether your time - saving automation needs are large or
                        small, we are here to help you scale.
                    </h3>
                    <div className="PlanAndPricingselector-duration">
                        <span
                            id="month"
                            className={`${
                                typeOfPlan === "month"
                                    ? "PlanAndPricingselector-color PlanAndPricingspan planborder1"
                                    : "PlanAndPricingother PlanAndPricingspan planborder1"
                            }`}
                            onClick={() => monthSelector("month")}
                        >
                            MONTHLY
                        </span>
                        <span
                            id="year"
                            className={`${
                                typeOfPlan === "year"
                                    ? "PlanAndPricingselector-color PlanAndPricingspan planborder2"
                                    : "PlanAndPricingother PlanAndPricingspan planborder"
                            }`}
                            onClick={() => yearSelector("year")}
                        >
                            YEARLY
                        </span>
                    </div>
                    <div className="PlanAndPricing--itemContainer">
                        {plans?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => setSelectedPlan(item?._id)}
                                    style={{ cursor: "pointer" }}
                                    key={item?._id}
                                    className={`PlanAndPricing--item ${
                                        item?._id === selectedPlan &&
                                        "PlanAndPricingbg-color"
                                    }`}
                                >
                                    <div className="PlanAndPricingdiv">
                                        <h5
                                            className="PlanAndPricingh5"
                                            style={
                                                item._id === selectedPlan
                                                    ? { visibility: "visible" }
                                                    : { visibility: "hidden" }
                                            }
                                        >
                                            MOST POPULAR
                                        </h5>
                                        {typeOfPlan === "month" ? (
                                            <div className="d-flex">
                                                <h2 className="PlanAndPricingh2">
                                                    ${item?.monthPrice}
                                                </h2>{" "}
                                                &nbsp;
                                                <h5
                                                    style={{
                                                        fontWeight: "500",
                                                        color:
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "#ffffff"
                                                                : "#848199",
                                                        marginTop: "1.6em",
                                                    }}
                                                >
                                                    {" "}
                                                    {"/month"}
                                                </h5>
                                            </div>
                                        ) : (
                                            <div className="d-flex">
                                                <h2 className="PlanAndPricingh2">
                                                    ${item?.yearPrice}
                                                </h2>{" "}
                                                &nbsp;
                                                <h5
                                                    style={{
                                                        fontWeight: "500",
                                                        color:
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "#ffffff"
                                                                : "#848199",
                                                        marginTop: "1.6em",
                                                    }}
                                                >
                                                    {" "}
                                                    {"/yearly"}
                                                </h5>
                                            </div>
                                        )}

                                        <h4 className="PlanAndPricingh4">
                                            {item?.name}
                                        </h4>
                                        <p className="PlanAndPricingp">
                                            {index === 0
                                                ? "Unleash the power of automation."
                                                : index === 1
                                                ? "Advanced tools to take your work to the next level."
                                                : "Automation plus enterprise-grade features."}
                                        </p>
                                        {typeOfPlan === "month" ? (
                                            <>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {`${item?.dynamic} Dynamic QR Code`}
                                                </span>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {/* {`${item?.static} Static QR Code`} */}
                                                    {`Unlimited Static QR Code`}
                                                </span>

                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {/* {`${item?.scans} Scans`} */}
                                                    {item?.scans == "No" ? `${item.scans} Scans/Tracking` : `${item.scans} Scans`}
                                                </span>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {`${item?.users} Users`}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {`${item?.yeardynamic} Dynamic QR Code`}
                                                </span>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {/* {`${item?.yearstatic} Static QR Code`} */}
                                                    {`Unlimited Static QR Code`}
                                                </span>

                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {/* {`${item?.yearscans} Scans`} */}
                                                    {item?.yearscans == 0 ? `${item.yearscans} Scans/Tracking` : `${item.yearscans} Scans`}
                                                </span>
                                                <span className="PlanAndPricingspan">
                                                    <DoneIcon
                                                        className={
                                                            item?._id ===
                                                            selectedPlan
                                                                ? "arrowcss"
                                                                : "PlanAndPricingdoneIcon"
                                                        }
                                                    />
                                                    &nbsp;
                                                    {`${item?.yearusers} Users`}
                                                </span>
                                            </>
                                        )}
                                    </div>

                                    <Button
                                        onClick={() => {
                                            planSelectorHandler(item?._id);
                                        }}
                                        id={`${item?.name
                                            ?.toLowerCase()
                                            ?.replace(" ", "_")}Plan`}
                                        className={`PlanAndPricingbtn ${
                                            item?._id !== selectedPlan &&
                                            "disabled-btn"
                                        }`}
                                        variant="contained"
                                    >
                                        Choose Plan
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
